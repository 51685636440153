<template>
    <div class="outerBox">
		<div class="innerBox">
			<img class="cultureIcon" :src="iconSrc" alt="">
			<div class="cultureInfo">
				<div class="cultureName">
					{{itemName}}
					<div class="tag" :style="{backgroundColor: tagColor}">
						<span style="margin: 0 0.3em">{{tag}}</span>
					</div>
				</div>
				<div class="valueRow">
					<active-number class="envValue" :number="value"></active-number>
					<span class="unit">{{unit}}</span>
				</div>
			</div>
		</div>
		<div class="subTextBox">
			<span class="subText">{{subText}}</span>
		</div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	height: 100%;
	border: 1px solid;
	border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
	background: linear-gradient(90deg, rgba(72,64,42,0.4) 0%, rgba(61,61,61,0) 100%);

	.innerBox {
		width: 77.22%;
		height: 49.48%;
		margin: auto;
		margin-top: 1.11vh;
		display: flex;
		align-items: center;

		.cultureIcon {
			height: 100%;
			aspect-ratio: 1/1;
			border-radius: 4px;
		}
		.cultureInfo {
			margin-left: 6.66%;
			height: 70%;
			width: 100%;

			.cultureName {
				height: 1.85vh;
				font-size: 1.3vh;
				line-height: 1.85vh;
				display: flex;
				align-items: center;
				color: #fff;
				font-family: 'pingfangM', sans-serif;
				font-weight: 500;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				.tag {
					height: 1.3vh;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.93vh;
					color: #fff;
					font-family: 'titleFont', sans-serif;
					line-height: 1.3vh;
					border-radius: 0.65vh;
					margin-left: 2.3%;
				}
			}
			
			.valueRow {
				height: 2.5vh;
				width: 100%;
				
				.envValue {
					line-height: 2.5vh;
					font-size: 2.22vh;
					background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-family: 'titleFont', sans-serif;
					font-weight: 400;
				}
				.unit {
					font-size: 1.11vh;
					color: #fff;
					font-family: 'pingfangM', sans-serif;
					font-weight: 500;
					margin-left: 3%;
				}
			}
		}
	}
	.subTextBox {
		width: 100%;
		height: 40.21%;
		line-height: 1.574vh;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.11vh;
		font-family: 'timerFont', sans-serif;
		color: #fff
	}
}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:{
		inputData:{
			type: Object
		}
	},
	components:{
		activeNumber
	},
	data(){
		return {
			itemName: '',
			iconSrc: '',
			value: 0,
			unit: '',
			subText: '',
			tag: '',
			tagColor: ''
		}
	},
	mounted(){
		this.initData();
		return false;
	},
	watch:{
		inputData(){
			this.initData();
			return false;
		}
	},
	methods: {
		initData(){
			let {itemName, iconSrc, value, unit, subText, tag, tagColor} = this.inputData;
			let ctx = require.context('@/assets/page3',false,/.png$/);
			this.iconSrc = ctx(iconSrc);
			this.itemName = itemName;
			this.value = value;
			this.unit = unit;
			this.subText = subText;
			this.tag = tag;
			this.tagColor = tagColor;
		}
	}
}
</script>

